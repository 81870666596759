var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-xl",attrs:{"loading":_vm.loading,"loading-text":"Please wait ....Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.status_datetime))+" ")]}},{key:"item.case_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.case.case_id)+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.case.client.name)+" "),(item.case.client.email)?_c('div',[_vm._v(_vm._s(item.case.client.email))]):_vm._e(),(item.case.client.mobile)?_c('div',[_vm._v(" "+_vm._s(item.case.client.mobile_display)+" ")]):_vm._e()])]}},{key:"item.clause",fn:function(ref){
var item = ref.item;
return [(item.clause)?_c('div',[_vm._v(" "+_vm._s(item.clause.name)+" ")]):_vm._e(),_vm._v(" Refund Percentage: "+_vm._s(item.refund_percent)+"% ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("University: ₹"+_vm._s(item.university_cost))]),_c('div',[_vm._v("Processing: ₹"+_vm._s(item.processing_cost))]),_c('div',[_vm._v("Shipping: ₹"+_vm._s(item.shipping_cost))]),_c('div',[_vm._v("Total: ₹"+_vm._s(item.total_refund_amount))])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [(item.remarks)?_c('div',[_vm._v(" "+_vm._s(item.remarks)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize font-weight-bold",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status_display)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(
        item.actions.includes('refund_edit') ||
        item.actions.includes('refund_delete')
      )?_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[(item.actions.includes('refund_edit'))?_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editRefund(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),(item.actions.includes('refund_delete'))?_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteRefund(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e()],1):_c('v-icon',[_vm._v("mdi-minus")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }