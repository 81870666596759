<template>
  <v-data-table
    :loading="loading"
    class="rounded-xl"
    loading-text="Please wait ....Loading"
    :headers="headers"
    :items="items"
    hide-default-footer
    mobile-breakpoint
  >
    <template v-slot:[`item.created`]="{ item }">
      {{ $utils.formatDate(item.created) }}
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <div class="py-4">
        <span v-if="item.is_splitted">
          <div>FE/IR: ₹{{ item.fe_ir_amount }}</div>
          <div>University: ₹{{ item.university_amount }}</div>
          <div>Total: ₹{{ item.amount }}</div>
        </span>
        <span v-else>₹{{ item.amount }}</span>
      </div>
    </template>

    <template v-slot:[`item.remarks`]="{ item }">
      <div class="py-4" v-if="item.remarks">
        {{ item.remarks }}
      </div>
      <div v-else>
        <v-icon>mdi-minus</v-icon>
      </div>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        small
        class="text-capitalize lighten-5 text--darken-2 font-weight-bold"
        :class="
          item.status == 'successful'
            ? 'success success--text'
            : item.status == 'pending'
            ? 'blue blue--text'
            : 'error error--text'
        "
      >
        {{ item.status }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },

  props: {
    caseId: Number,
    headers: Array,
  },

  mounted() {
    this.getPayoutList();
  },

  methods: {
    getPayoutList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        case_id: this.caseId,
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.case.payout.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>