<template>
  <v-hover v-slot="{ hover }">
    <v-slide-x-transition>
      <v-sheet
        :outlined="type === 'sender'"
        class="pa-3"
        :class="
          type === 'sender'
            ? 'rounded-tr-0 rounded-xl'
            : 'rounded rounded-tl-0 rounded-xl'
        "
        width="100%"
        max-width="40%"
        :elevation="hover ? 2 : 0"
      >
        <div class="text-caption primary--text font-weight-medium">
          <span v-if="message.sender === $keys.CLIENT">
            {{ message.case.client.name }}
          </span>
          <span v-if="message.sender === $keys.TEAM">
            <span v-if="message.team.user.id === user.id"> You </span>
            <span v-else>
              {{ message.team.name }} ({{ message.team.role_display }})
            </span>
          </span>
          <span v-if="message.sender === $keys.FE_IR">
            {{ message.fe_ir.name }} ({{ message.fe_ir.role_display }})
          </span>
        </div>
        <div class="mb-1">
          <div
            v-if="message.file"
            class="my-2 pointer"
            @click="openFile(message.file)"
          >
            <div v-if="isImage">
              <v-img :src="message.file" />
            </div>
            <div v-else class="d-flex align-center file-container">
              <v-icon>mdi-file</v-icon>
              <div class="ml-1 text-caption text-truncate">
                {{ message.file_name }}
              </div>
            </div>
          </div>
          <div v-if="message.text" v-html="message.text"></div>
        </div>
        <div
          class="d-flex align-center justify-end text-caption text--secondary"
          style="gap: 5px"
        >
          {{ dateFormat }}
          <div v-if="type === 'sender'">
            <v-icon small :color="message.is_seen ? 'green' : ''"
              >mdi-check-all</v-icon
            >
          </div>
        </div>
      </v-sheet>
    </v-slide-x-transition>
  </v-hover>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    isImage() {
      if (this.message.file) {
        return this.message.file_mimetype.startsWith("image");
      }
      return false;
    },
    dateFormat() {
      return this.$moment(this.message.created).format("hh:mm a");
    },
  },
  methods: {
    openFile(file_url) {
      window.open(file_url, "_blank");
    },
  },
};
</script>
<style scoped>
.rounded {
  border: 1px solid var(--v-primary-base);
}
.file-container {
  padding: 4px;
  border: 1px dashed var(--v-secondary-base);
  border-radius: 6px;
}
</style>