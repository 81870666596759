var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-xl",attrs:{"loading":_vm.loading,"loading-text":"Please wait ....Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.created))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4"},[(item.is_splitted)?_c('span',[_c('div',[_vm._v("FE/IR: ₹"+_vm._s(item.fe_ir_amount))]),_c('div',[_vm._v("University: ₹"+_vm._s(item.university_amount))]),_c('div',[_vm._v("Total: ₹"+_vm._s(item.amount))])]):_c('span',[_vm._v("₹"+_vm._s(item.amount))])])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [(item.remarks)?_c('div',{staticClass:"py-4"},[_vm._v(" "+_vm._s(item.remarks)+" ")]):_c('div',[_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize lighten-5 text--darken-2 font-weight-bold",class:item.status == 'successful'
          ? 'success success--text'
          : item.status == 'pending'
          ? 'blue blue--text'
          : 'error error--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }