<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="472px"
      content-class="rounded-xl"
    >
      <v-overlay class="justify-center align-center" v-if="loading">
        <v-progress-circular
          :size="100"
          :width="6"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div class="text-h6">Add Documents</div>
          <v-btn icon>
            <v-icon @click="modalClosed" size="26">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="d-flex justify-space-between my-5" v-if="caseDetail">
            <div class="sub-title text-start">
              {{ caseDetail.client.name }}
              <div style="opacity: 0.6">
                {{ caseDetail.client.user.mobile_display }} |
                {{ caseDetail.client.user.email }}
              </div>
            </div>

            <div class="sub-title text-end">
              Case Id
              <div style="opacity: 0.6">
                {{ caseDetail.case_id }}
              </div>
            </div>
          </div>
          <v-form lazy-validation ref="form">
            <v-text-field
              v-model="form.name"
              label="Name"
              :rules="[$rules.required]"
              height="42px"
              type="text"
              outlined
              dense
              class="rounded-lg"
            ></v-text-field>

            <div class="file-input-container mt-2">
              <v-file-input
                accept="image/*, application/pdf"
                id="file-input"
                v-show="false"
                @change="onFileChange"
              ></v-file-input>
              <label for="file-input">
                <div class="file-input d-flex align-center justify-center">
                  <div v-if="form.file">
                    <v-img
                      :src="form.previewFile"
                      height="120"
                      width="120"
                      v-if="form.file.type.startsWith('image/')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon v-else size="50">mdi-file</v-icon>
                  </div>
                  <v-icon v-else size="40">mdi-upload</v-icon>
                </div>
              </label>
              <v-icon
                size="20"
                v-if="form.file"
                color="primary"
                @click="removeFile"
                class="file-remover"
                >mdi-close-circle</v-icon
              >
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="submit"
            :loading="btnLoading"
            color="primary"
            class="text-capitalize px-8 my-2 rounded-lg"
            height="40px"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      loading: false,
      caseDetail: null,
      form: {
        name: "",
        file: null,
        previewFile: null,
      },
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  computed: {
    ...mapGetters({
      addDocumentDialog: "dialogs/addDocumentDialog",
    }),
    show: {
      get() {
        return this.addDocumentDialog.show;
      },
      set(value) {
        this.toggleAddDocumentDialog({ show: value });
      },
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleAddDocumentDialog: "dialogs/toggleAddDocumentDialog",
    }),

    async openModal() {
      this.loading = true;
      await this.getCaseDetail();
      this.loading = false;
    },

    modalClosed() {
      this.form = {
        name: "",
        file: null,
        previewFile: null,
      };
      this.toggleAddDocumentDialog({ show: false });
    },

    onFileChange(file) {
      if (file) {
        this.form.file = file;
        if (file.type.startsWith("image/")) {
          this.form.previewFile = URL.createObjectURL(file);
        }
        URL.revokeObjectURL(file);
      } else {
        this.form.previewFile = null;
      }
    },

    removeFile() {
      this.form.file = null;
      this.form.previewFile = null;
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = new FormData();
        data.append("name", this.form.name);
        data.append("case", this.addDocumentDialog.caseId);

        if (this.form.file) {
          data.append("file", this.form.file);
          data.append("status", this.$keys.UPLOADED);
        }

        return this.$request(this.$keys.POST, this.$urls.case.document.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },

    getCaseDetail() {
      const onSuccess = (res) => {
        this.caseDetail = res.data.data;
      };

      const params = {
        case_id: this.$route.params.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}
.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.title-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.file-input-container {
  position: relative;
  border: 1px dashed #9e9e9e;
  box-sizing: border-box;
  border-radius: 2px;
  width: 130px;
  height: 130px;
}

.file-input {
  height: 100%;
  cursor: pointer;
}

.file-remover {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
</style>
