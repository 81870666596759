<template>
  <main>
    <div class="text-center mt-16" v-if="loading">
      <v-progress-circular
        :size="60"
        :width="2"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-else>
      <div class="d-flex justify-space-between align-center">
        <!-- left -->
        <div class="d-flex align-center" style="gap: 10px">
          <v-btn icon @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="title">Case Details</div>
          <v-chip color="primary" small v-if="caseDetail">
            {{ caseDetail.status_display }}
          </v-chip>
        </div>

        <!-- right -->
        <div v-if="caseDetail" class="d-flex align-center" style="gap: 10px">
          <v-btn
            outlined
            height="32px"
            color="primary"
            class="text-capitalize rounded-lg"
            small
            @click="editCase"
            v-if="caseDetail.actions.includes('edit_case')"
          >
            <v-icon left>mdi-pencil-outline</v-icon>
            Edit
          </v-btn>
          <v-btn
            outlined
            height="32px"
            color="primary"
            class="text-capitalize rounded-lg"
            small
            v-if="caseDetail.actions.includes('delete_case')"
            @click="openConfirmationDialog('delete_case')"
          >
            <v-icon left>mdi-trash-can-outline</v-icon>
            Delete
          </v-btn>
        </div>
      </div>

      <v-card class="my-6" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="text-body-1 font-weight-medium">
          Basic Information
        </v-card-title>
        <v-divider />
        <v-card-text class="">
          <div class="d-flex flex-wrap" v-if="caseDetail" style="gap: 10px 0px">
            <v-sheet width="20%">
              <div class="text--secondary">Case id</div>
              <div class="text-capitalize">
                {{ caseDetail.case_id }}
              </div>
            </v-sheet>
            <v-sheet width="20%">
              <div class="text--secondary">Name</div>
              <div class="text-capitalize">
                {{ caseDetail.client.name }}
              </div>
            </v-sheet>
            <v-sheet width="20%">
              <div class="text--secondary">Mobile</div>
              <div class="text-capitalize">
                {{ caseDetail.client.user.mobile_display }}
              </div>
            </v-sheet>
            <v-sheet width="20%">
              <div class="text--secondary">Email</div>
              <div class="">
                {{ caseDetail.client.user.email }}
              </div>
            </v-sheet>

            <v-sheet width="20%">
              <div class="text--secondary">Service</div>
              <div class="text-capitalize">
                {{ caseDetail.service.name }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.UNIVERSITY)">
              <div class="text--secondary">University</div>
              <div class="text-capitalize" v-if="caseDetail.university">
                {{ caseDetail.university.name }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.STATE)">
              <div class="text--secondary">State</div>
              <div class="text-capitalize" v-if="caseDetail.state">
                {{ caseDetail.state.name }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.CITY)">
              <div class="text--secondary">City</div>
              <div class="text-capitalize" v-if="caseDetail.city">
                {{ caseDetail.city.name }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.EDUCATION)">
              <div class="text--secondary">Education</div>
              <div class="text-capitalize" v-if="caseDetail.education">
                {{ caseDetail.education.name }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.COURSE)">
              <div class="text--secondary">Course</div>
              <div class="text-capitalize" v-if="caseDetail.course">
                {{ caseDetail.course.name }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.NO_OF_SETS)">
              <div class="text--secondary">No. of Sets</div>
              <div class="text-capitalize">
                {{ caseDetail.no_of_sets }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.NO_OF_DOCUMENTS)">
              <div class="text--secondary">No. of Documents</div>
              <div class="text-capitalize">
                {{ caseDetail.no_of_documents }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.DOCUMENT_TYPE)">
              <div class="text--secondary">Document Type</div>
              <div class="text-capitalize">
                {{ caseDetail.document_type }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="caseDetail.eca.length">
              <div class="text--secondary">ECA Body</div>
              <div class="text-capitalize">
                <li v-for="eca in caseDetail.eca" :key="`eca-${eca.id}`">
                  {{ eca.name }}
                </li>
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="caseDetail.eca_reference_number">
              <div class="text--secondary">ECA Reference Number</div>
              <div class="text-capitalize">
                {{ caseDetail.eca_reference_number }}
              </div>
            </v-sheet>

            <v-sheet width="20%">
              <div class="text--secondary">Delivery Type</div>
              <div class="text-capitalize">
                {{ caseDetail.delivery_type_display }}
              </div>
            </v-sheet>

            <v-sheet width="60%">
              <div class="text--secondary">Remarks</div>
              <div class="text-capitalize" v-if="caseDetail.remarks">
                {{ caseDetail.remarks }}
              </div>
              <div v-else>
                <v-icon>mdi-minus</v-icon>
              </div>
            </v-sheet>
          </div>

          <v-divider class="my-4" />

          <div class="d-flex flex-wrap" v-if="caseDetail">
            <v-sheet width="20%" class="pr-4">
              <div class="text--secondary">Client TAT</div>
              <div class="text-capitalize">
                {{ caseDetail.client_tat_days }}
                {{ caseDetail.client_tat_days > 1 ? " days" : " day" }}
                <span
                  v-if="
                    ['completed', 'started'].includes(caseDetail.tat_status)
                  "
                >
                  <span
                    v-if="caseDetail.tat_status === 'completed'"
                    v-text="
                      caseDetail.client_tat_days_left >= 1
                        ? `(Closed ${caseDetail.client_tat_days_left} days early)`
                        : caseDetail.client_tat_days_left <= -1
                        ? `(Closed ${caseDetail.client_tat_days_left} days late)`
                        : `(Closed on time)`
                    "
                  ></span
                  ><span
                    v-else
                    v-text="
                      caseDetail.client_tat_days_left >= 1
                        ? `(${caseDetail.client_tat_days_left} days left)`
                        : caseDetail.client_tat_days_left <= -1
                        ? `(${caseDetail.client_tat_days_left} days exceeded)`
                        : `(due today)`
                    "
                  ></span>
                </span>
              </div>
            </v-sheet>
            <v-sheet width="20%" class="pr-4">
              <div class="text--secondary">Internal TAT</div>
              <div class="text-capitalize">
                {{ caseDetail.internal_tat_days }}
                {{ caseDetail.internal_tat_days > 1 ? " days" : " day" }}
                <span
                  v-if="
                    ['completed', 'started'].includes(caseDetail.tat_status)
                  "
                >
                  <span
                    v-if="caseDetail.tat_status === 'completed'"
                    v-text="
                      caseDetail.internal_tat_days_left >= 1
                        ? `(Closed ${caseDetail.internal_tat_days_left} days early)`
                        : caseDetail.internal_tat_days_left <= -1
                        ? `(Closed ${caseDetail.internal_tat_days_left} days late)`
                        : '(Closed on time)'
                    "
                  >
                  </span
                  ><span
                    v-else
                    v-text="
                      caseDetail.internal_tat_days_left >= 1
                        ? `(${caseDetail.internal_tat_days_left} days left)`
                        : caseDetail.internal_tat_days_left <= -1
                        ? `(${caseDetail.internal_tat_days_left} days exceeded)`
                        : '(due today)'
                    "
                  >
                  </span>
                </span>
              </div>
            </v-sheet>

            <v-sheet width="60%" class="pr-4 d-flex align-center">
              <span v-if="caseDetail.actions.includes('request_tat_change')">
                <span v-if="caseDetail.tat_change_request_status === 'pending'">
                  <v-chip color="primary">
                    TAT change request in process
                  </v-chip>
                </span>
                <span
                  v-else
                  class="primary--text text-decoration-underline pointer"
                  @click="
                    requestTatModal({
                      show: true,
                      type: 'add',
                      item: caseDetail,
                    })
                  "
                >
                  Request to change TAT
                </span>
                <v-icon size="24">mdi-tally-mark-1</v-icon>
              </span>
              <span
                @click="
                  toggleTatHistoryDialog({
                    show: true,
                    caseId: $route.params.id,
                  })
                "
                class="primary--text text-decoration-underline pointer"
              >
                View TAT Change History
              </span>
            </v-sheet>
          </div>

          <v-divider class="my-4" />

          <div class="d-flex flex-wrap" v-if="caseDetail">
            <v-sheet width="25%" class="pr-4" v-if="caseDetail.sales">
              <div class="text--secondary">Sales Team</div>
              <div class="">
                <div>
                  {{ caseDetail.sales.name }}
                </div>
                <div v-if="caseDetail.sales.user.email">
                  Email: {{ caseDetail.sales.user.email }}
                </div>
                <div v-if="caseDetail.sales.user.mobile">
                  Mobile: {{ caseDetail.sales.user.mobile_display }}
                </div>
              </div>
            </v-sheet>

            <v-sheet width="25%" class="pr-4" v-if="caseDetail.operations">
              <div class="text--secondary">Operations Team</div>
              <div class="">
                <div>
                  {{ caseDetail.operations.name }}
                </div>
                <div v-if="caseDetail.operations.user.email">
                  Email: {{ caseDetail.operations.user.email }}
                </div>
                <div v-if="caseDetail.operations.user.mobile">
                  Mobile: {{ caseDetail.operations.user.mobile_display }}
                </div>
              </div>
            </v-sheet>

            <v-sheet width="25%" class="pr-4" v-if="caseDetail.fe_ir">
              <div class="text--secondary">FE/IR</div>
              <div class="">
                <div>
                  {{ caseDetail.fe_ir.name }}
                </div>
                <div v-if="caseDetail.fe_ir.user.email">
                  Email: {{ caseDetail.fe_ir.user.email }}
                </div>
                <div v-if="caseDetail.fe_ir.user.mobile">
                  Mobile: {{ caseDetail.fe_ir.user.mobile_display }}
                </div>
              </div>
            </v-sheet>

            <v-sheet width="25%" class="pr-4" v-if="caseDetail.vendor">
              <div class="text--secondary">Vendor</div>
              <div class="">
                <div>
                  {{ caseDetail.vendor.name }}
                </div>
                <div v-if="caseDetail.vendor.user.email">
                  Email: {{ caseDetail.vendor.user.email }}
                </div>
                <div v-if="caseDetail.vendor.user.mobile">
                  Mobile: {{ caseDetail.vendor.user.mobile_display }}
                </div>
              </div>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="my-6" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="text-body-1 font-weight-medium">
          Pricing Information
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="d-flex flex-wrap" v-if="caseDetail" style="gap: 10px 0px">
            <v-sheet width="20%" v-if="hasPermission($keys.UNIVERSITY_COST)">
              <div class="text--secondary">University Cost</div>
              <div class="text-capitalize">
                {{ caseDetail.university_cost }}
              </div>
            </v-sheet>

            <v-sheet
              width="20%"
              v-if="hasPermission($keys.UNIVERSITY_COST_PER_DOCUMENT)"
            >
              <div class="text--secondary">University Cost/Document</div>
              <div class="text-capitalize">
                {{ caseDetail.university_cost_per_document }}
              </div>
            </v-sheet>

            <v-sheet
              width="20%"
              v-if="hasPermission($keys.UNIVERSITY_ADDITIONAL_COST)"
            >
              <div class="text--secondary">University Additional Cost</div>
              <div class="text-capitalize">
                {{ caseDetail.university_additional_cost }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.PROCESSING_COST)">
              <div class="text--secondary">Processing Cost</div>
              <div class="text-capitalize">
                {{ caseDetail.processing_cost }}
              </div>
            </v-sheet>

            <v-sheet
              width="20%"
              v-if="hasPermission($keys.PROCESSING_COST_PER_COPY)"
            >
              <div class="text--secondary">Processing Cost/Copy</div>
              <div class="text-capitalize">
                {{ caseDetail.processing_cost_per_copy }}
              </div>
            </v-sheet>

            <v-sheet
              width="20%"
              v-if="hasPermission($keys.PROCESSING_COST_PER_DOCUMENT)"
            >
              <div class="text--secondary">Processing Cost/Document</div>
              <div class="text-capitalize">
                {{ caseDetail.processing_cost_per_document }}
              </div>
            </v-sheet>

            <v-sheet
              width="20%"
              v-if="hasPermission($keys.PROCESSING_ADDITIONAL_COST)"
            >
              <div class="text--secondary">Processing Additional Charges</div>
              <div class="text-capitalize">
                {{ caseDetail.processing_additional_cost }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.SHIPPING_COST)">
              <div class="text--secondary">Shipping Cost</div>
              <div class="text-capitalize">
                {{ caseDetail.shipping_cost }}
              </div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.GST_PERCENT)">
              <div class="text--secondary">GST %</div>
              <div class="text-capitalize">{{ caseDetail.gst_percent }}</div>
            </v-sheet>

            <v-sheet width="20%" v-if="hasPermission($keys.DISCOUNT_PERCENT)">
              <div class="text--secondary">Discount %</div>
              <div class="text-capitalize">
                {{ caseDetail.discount_percent }}
              </div>
            </v-sheet>
          </div>

          <v-sheet
            outlined
            rounded="xl"
            class="mt-6 d-flex align-center justify-end pa-3"
            v-if="caseDetail"
          >
            <div
              class="text-right"
              v-if="hasPermission($keys.TOTAL_UNIVERSITY_COST)"
            >
              <div class="text--secondary">Total University Cost</div>
              <div class="text-capitalize">
                ₹ {{ caseDetail.total_university_cost }}
              </div>
            </div>

            <v-divider
              vertical
              class="mx-4"
              v-if="hasPermission($keys.TOTAL_UNIVERSITY_COST)"
            ></v-divider>

            <div class="text-right">
              <div class="text--secondary">Total Processing Cost</div>
              <div class="text-capitalize">
                ₹ {{ caseDetail.total_processing_cost }}
              </div>
            </div>

            <v-divider vertical class="mx-4"></v-divider>

            <div class="text-right">
              <div class="text--secondary">GST Amount</div>
              <div class="text-capitalize">₹ {{ caseDetail.gst_amount }}</div>
            </div>

            <v-divider vertical class="mx-4"></v-divider>

            <div class="text-right">
              <div class="text--secondary">Discount Amount</div>
              <div class="text-capitalize">
                ₹ {{ caseDetail.discount_amount }}
              </div>
            </div>

            <v-divider vertical class="mx-4"></v-divider>

            <div class="text-right">
              <div class="text--secondary">Total Amount</div>
              <div class="text-capitalize">₹ {{ caseDetail.total_amount }}</div>
            </div>
          </v-sheet>

          <v-sheet class="mt-4">
            <div class="text-body-2 font-weight-medium">Additional Funds</div>
            <div
              class="d-flex flex-wrap mt-2"
              v-if="caseDetail"
              style="gap: 10px 0px"
            >
              <v-sheet
                width="20%"
                v-for="(item, index) in caseDetail.funds"
                :key="`funds-item-${index}`"
              >
                <div class="text--secondary">{{ item.title }}</div>
                <div class="text-capitalize">₹ {{ item.value }}</div>
              </v-sheet>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>

      <v-card class="my-6" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="text-body-1 font-weight-medium">Quotation History</div>
          <div v-if="!loading" class="d-flex">
            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              @click="openQuotationDialog"
              v-if="caseDetail.actions.includes('send_quotation')"
            >
              Send New Quotation
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <div v-if="caseDetail">
            <v-data-table
              class="rounded-xl"
              :loading="loading"
              loading-text="Please wait ....Loading"
              :headers="quotationHeader"
              :items="quotations"
              hide-default-footer
              mobile-breakpoint
            >
              <template v-slot:[`item.sent_by`]="{ item }">
                <div class="py-4" v-if="item.sent_by">
                  {{ item.sent_by.name }}
                </div>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ $utils.formatDate(item.created) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div
                  class="py-4 d-flex align-center justify-center"
                  v-if="item.total_amount"
                >
                  <span class="mr-2">₹{{ item.total_amount }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="pointer">
                        mdi-information-variant-circle-outline
                      </v-icon>
                    </template>
                    <div>
                      <div v-if="item.total_university_cost">
                        University Cost: ₹{{ item.total_university_cost }}
                      </div>
                      <div>
                        Processing Cost: ₹{{ item.total_processing_cost }}
                      </div>
                      <div>Shipping Cost: ₹{{ item.shipping_cost }}</div>
                      <div>GST Amount: ₹{{ item.gst_amount }}</div>
                      <div>Discount Amount: ₹{{ item.discount_amount }}</div>
                    </div>
                  </v-tooltip>
                </div>
                <v-icon size="20" v-else>mdi-minus</v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  class="text-capitalize lighten-5 text--darken-2 font-weight-bold"
                  :class="
                    item.is_active
                      ? 'success success--text'
                      : 'error error--text'
                  "
                >
                  {{ item.is_active ? "Active" : "Inactive" }}
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="my-6" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex align-center">
          <div class="text-body-1 font-weight-medium">Payment History</div>
          <v-spacer />
          <div v-if="caseDetail" class="d-flex" style="gap: 10px">
            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              @click="
                toggleAddUpdateDialog({
                  show: true,
                  caseId: $route.params.id,
                  type: 'add',
                })
              "
              v-if="caseDetail.actions.includes('payment_add')"
            >
              <v-icon size="18" left>mdi-plus</v-icon>
              Add Payment Record
            </v-btn>

            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              @click="
                toggleRequestAdditionalPaymentDialog({
                  show: true,
                  caseId: $route.params.id,
                  type: 'add',
                })
              "
              v-if="caseDetail.actions.includes('additional_payment_request')"
            >
              <v-icon size="18" left>mdi-plus</v-icon>
              Request Additional Payment
            </v-btn>

            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              @click="
                toggleAddAdditionalPaymentDialog({
                  show: true,
                  caseId: $route.params.id,
                  type: $keys.ADD,
                })
              "
              v-if="caseDetail.actions.includes('additional_payment_add')"
            >
              <v-icon size="18" left>mdi-plus</v-icon>
              Add Payment Record
            </v-btn>
          </div>
        </v-card-title>

        <v-divider />

        <v-card-text class="pa-0">
          <div v-if="caseDetail">
            <PaymentDataTable
              :caseId="caseDetail.id"
              :headers="paymentHeader"
              ref="paymentDataTable"
            />
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="my-6"
        :outlined="$vuetify.theme.dark"
        rounded="xl"
        v-if="
          caseDetail &&
          !loading &&
          [
            $keys.EDUCATIONAL_DOCUMENTATION,
            $keys.APOSTILLE_TRANSLATIONS,
            $keys.CERTIFICATIONS,
          ].includes(caseDetail.service.type)
        "
      >
        <v-card-title class="d-flex align-center">
          <div class="text-body-1 font-weight-medium">Payout History</div>
          <v-spacer />
          <div class="d-flex" style="gap: 10px">
            <v-btn
              color="primary"
              outlined
              small
              rounded
              class="text-capitalize rounded-lg"
              @click="
                payoutForm({
                  item: caseDetail,
                  show: true,
                  type: '',
                })
              "
              v-if="caseDetail.actions.includes('payout_add')"
            >
              <v-icon size="18" left>mdi-plus</v-icon>
              Request Payout
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <PayoutDataTable
            :caseId="caseDetail.id"
            :headers="payoutHeader"
            ref="payoutDataTable"
          />
        </v-card-text>
      </v-card>

      <v-card class="my-6" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex align-center">
          <div class="text-body-1 font-weight-medium">Refund History</div>
          <v-spacer />
          <div class="d-flex" style="gap: 10px" v-if="caseDetail">
            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              @click="
                toggleAddRefundDialog({
                  show: true,
                  caseId: $route.params.id,
                  type: $keys.ADD,
                })
              "
              v-if="caseDetail.actions.includes('refund_add')"
            >
              <v-icon size="18" left>mdi-plus</v-icon>
              Initiate Refund
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <div v-if="caseDetail">
            <RefundDataTable
              :caseId="caseDetail.id"
              :headers="refundHeader"
              ref="refundDataTable"
            />
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="my-6"
        :outlined="$vuetify.theme.dark"
        rounded="xl"
        v-if="
          caseDetail &&
          !loading &&
          [
            $keys.EDUCATIONAL_DOCUMENTATION,
            $keys.ELECTRONIC_TRANSCRIPTS,
          ].includes(caseDetail.service.type)
        "
      >
        <v-card-title class="d-flex align-center">
          <div class="text-body-1 font-weight-medium">Documents</div>
          <v-spacer />
          <div class="d-flex" style="gap: 10px">
            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              @click="
                toggleAddDocumentDialog({
                  show: true,
                  caseId: $route.params.id,
                })
              "
              v-if="caseDetail.actions.includes('document_add')"
            >
              <v-icon size="18" left>mdi-plus</v-icon>
              Add Document
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <DocumentDataTable
            :caseId="caseDetail.id"
            :headers="documentHeaders"
            ref="documentDataTable"
          />
        </v-card-text>
      </v-card>

      <div
        class="d-flex flex-wrap justify-space-between my-6"
        v-if="caseDetail"
      >
        <v-sheet width="49%" rounded="xl">
          <InternalChat :caseDetail="caseDetail" />
        </v-sheet>
        <v-sheet width="49%" rounded="xl">
          <ClientChat :caseDetail="caseDetail" />
        </v-sheet>
      </div>

      <!-- send quotation dialog -->
      <send-quotation-dialog @submitted="getCaseDetail" />

      <ConfirmationDialog @confirmed="confirmed" height="130px" width="130px">
        <template v-slot:confirmBtnText> Yes Delete </template>
      </ConfirmationDialog>

      <!-- documents -->
      <AddDocumentDialog @submitted="getDocumentList" />

      <!-- tat -->
      <request-tat @reload-case-detail="getCaseDetail" />
      <TatHistoryDialog />

      <!-- payments -->
      <AddUpdatePayment @submitted="getPaymentList" />
      <AddUpdateAdditionalPayment @on-submit="getPaymentList" />
      <RequestAdditionalPayment @on-submit="getPaymentList" />

      <!-- payouts -->
      <RequestPayoutDialog @submitted="getPayoutList" />

      <!-- refunds -->
      <AddUpdateRefundDialog @submitted="getRefundList" />
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import headers from "@/common/caseManagementHeader/caseManagementHeader";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";
import SendQuotationDialog from "@/components/Dialogs/SendQuotationDialog";

// tat
import RequestTat from "./modals/requestTat.vue";
import TatHistoryDialog from "@/components/Dialogs/TatHistoryDialog";

// documents
import AddDocumentDialog from "@/components/Dialogs/AddDocumentDialog";
import DocumentDataTable from "@/components/CaseManagement/Document/DataTable";

// payment
import PaymentDataTable from "@/components/CaseManagement/Payment/DataTable";
import AddUpdatePayment from "@/components/CaseManagement/Payment/AddUpdateDialog";
import AddUpdateAdditionalPayment from "@/components/CaseManagement/AdditionalPayment/AddUpdateDialog";
import RequestAdditionalPayment from "@/components/CaseManagement/AdditionalPayment/RequestDialog";

// payout
import PayoutDataTable from "@/components/CaseManagement/Payout/DataTable";
import RequestPayoutDialog from "@/components/CaseManagement/Payout/RequestDialog";

// refund
import RefundDataTable from "@/components/CaseManagement/Refund/DataTable";
import AddUpdateRefundDialog from "@/components/CaseManagement/Refund/AddUpdateDialog";

// chats
import ClientChat from "@/components/CaseManagement/ClientChat/ClientChat";
import InternalChat from "@/components/CaseManagement/InternalChat/InternalChat";

export default {
  components: {
    ConfirmationDialog,
    SendQuotationDialog,

    RequestTat,
    TatHistoryDialog,

    AddDocumentDialog,
    DocumentDataTable,

    PaymentDataTable,
    AddUpdatePayment,
    AddUpdateAdditionalPayment,
    RequestAdditionalPayment,

    PayoutDataTable,
    RequestPayoutDialog,

    RefundDataTable,
    AddUpdateRefundDialog,

    ClientChat,
    InternalChat,
  },

  data() {
    return {
      loading: false,

      paymentHeader: headers.payment_history,
      quotationHeader: headers.quotation_send,
      payoutHeader: headers.payout_history,
      refundHeader: headers.refund_history,
      documentHeaders: headers.document_req,

      caseDetail: null,
      quotations: [],

      confirmation_type: null,
    };
  },

  created() {
    this.$watch(
      () => this.$route.params,
      () => this.getCaseDetail()
    );
  },

  mounted() {
    this.getCaseDetail();
  },

  computed: {
    ...mapGetters({
      getUserDetail: "getUser",
    }),
  },

  methods: {
    ...mapActions({
      toggleSendQuotationDialog: "dialogs/toggleSendQuotationDialog",
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
      showSnackbar: "app/showSnackbar",
      toggleAddDocumentDialog: "dialogs/toggleAddDocumentDialog",
      togglePaymentModal: "paymentManagement/togglePaymentModal",
      toggleAddUpdateDialog: "payments/toggleAddUpdateDialog",
      payoutForm: "caseManagement/payoutForm",
      requestTatModal: "caseManagement/requestTatModal",
      toggleTatHistoryDialog: "dialogs/toggleTatHistoryDialog",
      toggleRequestAdditionalPaymentDialog:
        "additionalPayment/toggleRequestDialog",
      toggleAddAdditionalPaymentDialog:
        "additionalPayment/toggleAddUpdateDialog",
      toggleAddRefundDialog: "refund/toggleAddUpdateDialog",
      getNotificationCount: "topNav/getNotificationCount",
    }),

    hasPermission(field) {
      const service = this.caseDetail.service;

      if (service) {
        const _field = this.$_.find(service.form_fields, { key: field });
        return _field ? _field.show : false;
      }

      return false;
    },

    editCase() {
      this.$router.push({
        name: "caseEdit",
        params: {
          id: this.$route.params.id,
        },
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    deleteCase() {
      const onSuccess = (res) => {
        this.toggleConfirmationDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.$router.push({ name: "caseList" });
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      const params = {
        case_id: this.$route.params.id,
      };

      return this.$request(this.$keys.DELETE, this.$urls.case.delete, {
        params,
        onSuccess,
        onFinally,
      });
    },

    openQuotationDialog() {
      this.toggleSendQuotationDialog({
        show: true,
        caseId: this.caseDetail.id,
        clientId: this.caseDetail.client.id,
      });
    },

    openConfirmationDialog(type) {
      this.confirmation_type = type;
      this.toggleConfirmationDialog({ show: true, action: this.$keys.DELETE });
    },

    confirmed() {
      if (this.confirmation_type === "delete_case") {
        this.deleteCase();
      }
    },

    getCaseDetail() {
      this.loading = true;
      const onSuccess = (res) => {
        this.caseDetail = res.data.data;
        this.quotations = res.data.data.quotation_history;
        this.getNotificationCount();
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        case_id: this.$route.params.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getPaymentList() {
      this.$refs.paymentDataTable.getPaymentList();
    },

    getPayoutList() {
      this.$refs.payoutDataTable.getPayoutList();
    },

    getRefundList() {
      this.$refs.refundDataTable.getRefundList();
    },

    getDocumentList() {
      this.$refs.documentDataTable.getDocumentList();
    },
  },
};
</script>
