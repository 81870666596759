<template>
  <div>
    <v-data-table
      :loading="loading"
      class="rounded-xl"
      loading-text="Please wait ....Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.payment_date`]="{ item }">
        <div class="py-4" v-if="item.payment_date">
          {{ $utils.formatDate(item.payment_date) }}
        </div>
        <div v-else>
          <v-icon>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.mode`]="{ item }">
        <div class="py-4">
          <div class="mb-2" v-if="item.mode">
            {{ item.mode.name }}
          </div>
          <v-chip
            class="blue lighten-5 blue--text text--darken-2 font-weight-bold"
            small
          >
            {{ item.type_display }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <div
          v-if="item.is_additional"
          class="my-4 d-flex align-center justify-center"
        >
          <span class="mr-2" v-if="item.type === $keys.AUTOMATIC">
            ₹{{ item.payable_amount }}
          </span>
          <span class="mr-2" v-else>₹{{ item.total_amount }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="pointer">
                mdi-information
              </v-icon>
            </template>
            <div>
              <div>University Cost: ₹{{ item.university_cost }}</div>
              <div>
                FE/IR Cost: ₹{{ item.additional_university_cost }} +
                {{ item.gst_percent }}% tax
              </div>
              <div>
                Processing Cost: ₹{{ item.processing_cost }} +
                {{ item.gst_percent }}% tax
              </div>
              <div>
                Vendor Cost: ₹{{ item.additional_processing_cost }} +
                {{ item.gst_percent }}% tax
              </div>
              <div>Shipping Cost: ₹{{ item.shipping_cost }}</div>
              <div v-if="item.type === $keys.AUTOMATIC">
                Gateway Charges: ₹{{ item.gateway_charges }}
              </div>
            </div>
          </v-tooltip>
        </div>
        <div v-else>₹{{ item.amount }}</div>
      </template>

      <template v-slot:[`item.remarks`]="{ item }">
        <div class="my-4">
          <div v-if="item.remarks || item.is_additional">
            <div class="mb-2">
              <v-chip
                v-if="item.is_additional"
                class="orange lighten-5 orange--text text--darken-2 font-weight-bold"
                small
              >
                Additional
              </v-chip>
            </div>
            <div>{{ item.remarks }}</div>
          </div>
          <div v-else>
            <v-icon>mdi-minus</v-icon>
          </div>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="py-4">
          <v-chip
            small
            class="text-capitalize lighten-5 text--darken-2 font-weight-bold"
            :class="
              item.status == 'successful'
                ? 'success success--text'
                : item.status == 'pending'
                ? 'blue blue--text'
                : 'error error--text'
            "
          >
            {{ item.status }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="my-4">
          <div
            class="d-flex justify-center"
            style="gap: 10px"
            v-if="
              item.actions.includes('payment_edit') ||
              item.actions.includes('payment_delete') ||
              item.actions.includes('payment_receipt_download')
            "
          >
            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              v-if="item.actions.includes('payment_edit')"
              @click="editPayment(item)"
            >
              <v-icon left>mdi-pencil</v-icon> Edit
            </v-btn>

            <v-btn
              small
              outlined
              height="32px"
              color="primary"
              class="text-capitalize rounded-lg"
              v-if="item.actions.includes('payment_delete')"
              @click="deletePayment(item.id)"
            >
              <v-icon left>mdi-delete</v-icon> Delete
            </v-btn>

            <v-btn
              small
              outlined
              color="primary"
              class="text-capitalize rounded-lg"
              height="32px"
              v-if="item.actions.includes('payment_receipt_download')"
              @click="downloadReceipt(item.id)"
            >
              <v-icon left>mdi-download</v-icon>
              Download Receipt
            </v-btn>
          </div>
          <div v-else>
            <v-icon>mdi-minus</v-icon>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },

  props: {
    caseId: Number,
    headers: Array,
  },

  mounted() {
    this.getPaymentList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleRequestAdditionalPaymentDialog:
        "additionalPayment/toggleRequestDialog",
      toggleUpdateAdditionalPaymentDialog:
        "additionalPayment/toggleAddUpdateDialog",
      toggleUpdatePaymentDialog: "payments/toggleAddUpdateDialog",
    }),

    getPaymentList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        apply_pagination: false,
      };
      params["case_id"] = this.caseId;

      return this.$request(this.$keys.GET, this.$urls.case.payment.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    editPayment(item) {
      if (item.is_additional) {
        if (item.type == this.$keys.AUTOMATIC) {
          this.toggleRequestAdditionalPaymentDialog({
            show: true,
            caseId: this.$route.params.id,
            type: this.$keys.EDIT,
            id: item.id,
          });
        } else {
          this.toggleUpdateAdditionalPaymentDialog({
            show: true,
            caseId: this.$route.params.id,
            type: this.$keys.EDIT,
            id: item.id,
          });
        }
      } else {
        if (item.type == this.$keys.AUTOMATIC) return;
        this.toggleUpdatePaymentDialog({
          show: true,
          caseId: this.$route.params.id,
          type: this.$keys.EDIT,
          id: item.id,
        });
      }
    },

    deletePayment(payment_id) {
      const onSuccess = (res) => {
        this.showSnackbar({ text: res.data.message, color: "success" });
        this.getPaymentList();
      };

      const params = {
        payment_id: payment_id,
      };

      return this.$request(this.$keys.DELETE, this.$urls.case.payment.delete, {
        params,
        onSuccess,
      });
    },

    downloadReceipt(payment_id) {
      this.showSnackbar({
        text: "Please wait, download is in progress.",
        color: "success",
      });

      const params = {
        payment_id: payment_id,
      };

      const onSuccess = (res) => {
        let filename = "receipt.pdf";
        if (res.headers["content-disposition"])
          [, filename] = res.headers["content-disposition"].split("filename=");
        saveAs(res.data, filename);
      };

      const onFailure = () => {
        this.showSnackbar({
          text: "Something went wrong",
          color: "error",
        });
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.case.payment.downloadReceipt,
        {
          params,
          onSuccess,
          onFailure,
          responseType: "blob",
        }
      );
    },
  },
};
</script>
