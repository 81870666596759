<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
  >
    <Loading v-if="loading" />
    <v-card :outlined="$vuetify.theme.dark" rounded="xl" v-else>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Request Additional Payment</div>

        <v-btn icon @click="modalClosed()" color="primary">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <div class="d-flex flex-column">
            <v-text-field
              v-model="form.university_cost"
              label="University Cost"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('university_cost')"
              class="rounded-lg"
            ></v-text-field>

            <v-text-field
              v-model="form.additional_university_cost"
              label="Additional University Cost (FE/IR Cost)"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('additional_university_cost')"
              class="rounded-lg mb-2"
              hint="Please enter amount excluding gst"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="form.processing_cost"
              label="Processing Cost"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('processing_cost')"
              class="rounded-lg mb-2"
              hint="Please enter amount excluding gst"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="form.additional_processing_cost"
              label="Additional Processing Cost (Vendor Cost)"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('additional_processing_cost')"
              class="rounded-lg mb-2"
              hint="Please enter amount excluding gst"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="form.shipping_cost"
              label="Shipping Cost"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('shipping_cost')"
              class="rounded-lg"
            ></v-text-field>

            <v-text-field
              v-model="form.gst_percent"
              label="GST Percentage"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('gst_percent')"
              class="rounded-lg mb-2"
              hint="GST apples on Additional University Cost, Processing Cost and Additional Processing Cost"
              persistent-hint
              disabled
            ></v-text-field>

            <v-textarea
              rows="2"
              type="text"
              v-model="form.remarks"
              flat
              outlined
              dense
              hide-details
              label="Remarks"
              class="rounded-lg"
            ></v-textarea>
          </div>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-6 rounded-lg"
          :loading="btnLoading"
        >
          Submit Request
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      paymentDetail: null,
      form: {
        is_additional: true,
        is_requested: true,
        university_cost: 0,
        additional_university_cost: 0,
        processing_cost: 0,
        additional_processing_cost: 0,
        shipping_cost: 0,
        gst_percent: 0,
        remarks: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      requestDialog: "additionalPayment/requestDialog",
    }),

    show: {
      get() {
        return this.requestDialog.show;
      },
      set(value) {
        this.toggleRequestDialog({ show: value });
      },
    },

    type() {
      return this.requestDialog.type;
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleRequestDialog: "additionalPayment/toggleRequestDialog",
    }),

    validateNegativeNumber(field) {
      if (this.form[field] < 0) {
        this.form[field] = 0;
      }
    },

    async openModal() {
      this.loading = true;

      await this.getDefaultPricing();

      if (this.type === "edit") {
        await this.getPaymentDetail();
        const data = this.paymentDetail;
        this.form = {
          is_additional: data.is_additional,
          university_cost: data.university_cost,
          additional_university_cost: data.additional_university_cost,
          processing_cost: data.processing_cost,
          additional_processing_cost: data.additional_processing_cost,
          shipping_cost: data.shipping_cost,
          remarks: data.remarks,
        };
      }

      this.loading = false;
    },

    modalClosed() {
      this.form = {
        is_additional: true,
        university_cost: 0,
        additional_university_cost: 0,
        processing_cost: 0,
        additional_processing_cost: 0,
        shipping_cost: 0,
        remarks: "",
      };
      this.$refs.form.reset();
      this.toggleRequestDialog({ show: false });
    },

    getDefaultPricing() {
      const onSuccess = (res) => {
        this.form.gst_percent = res.data.data.gst_percent;
      };

      return this.$request(this.$keys.GET, this.$urls.case.defaultPricing, {
        onSuccess,
      });
    },

    getPaymentDetail() {
      const onSuccess = (res) => {
        this.paymentDetail = res.data.data;
      };

      const params = {
        payment_id: this.requestDialog.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.payment.detail, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        if (
          this.form.university_cost <= 0 &&
          this.form.additional_university_cost <= 0 &&
          this.form.processing_cost <= 0 &&
          this.form.additional_processing_cost <= 0 &&
          this.form.shipping_cost <= 0
        ) {
          this.showSnackbar({
            text: "Amount can not be 0.",
            color: "error",
          });
          this.btnLoading = false;
          return;
        }

        const data = { ...this.form };
        data["type"] = this.$keys.AUTOMATIC;
        const params = {};

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("on-submit");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        if (this.type === "edit") {
          params["payment_id"] = this.requestDialog.id;
          return this.$request(
            this.$keys.PATCH,
            this.$urls.case.payment.update,
            {
              params,
              data,
              onSuccess,
              onFinally,
            }
          );
        }

        data["case"] = this.requestDialog.caseId;
        return this.$request(this.$keys.POST, this.$urls.case.payment.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
