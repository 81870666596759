<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
    :retain-focus="false"
  >
    <Loading v-if="loading" />
    <v-card :outlined="$vuetify.theme.dark" rounded="xl" v-else>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6" v-if="type === 'edit'">
          Edit Additional Payment
        </div>
        <div class="text-h6" v-else>Add Additional Payment</div>

        <v-btn icon @click="modalClosed()" color="primary">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <div class="d-flex flex-column">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="auto"
              offset-y
              nudge-top="20"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-text-field
                  v-model="form.payment_date"
                  label="Payment date"
                  v-bind="attrs"
                  :rules="[$rules.required]"
                  height="42px"
                  solo
                  outlined
                  dense
                  flat
                  append-icon="mdi-calendar"
                  v-on="on"
                  readonly
                  class="rounded-lg"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                v-model="form.payment_date"
                no-title
                :max="maxDate"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>

            <v-select
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              :rules="[$rules.required]"
              append-icon="mdi-chevron-down"
              v-model="form.mode"
              :items="payment_mode_list"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              label="Select Payment Mode"
              class="rounded-lg"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize">
                    {{ data.item.name }} -
                    {{ data.item.type }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <v-text-field
              v-model="form.university_cost"
              label="University Cost"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('university_cost')"
              class="rounded-lg"
            ></v-text-field>

            <v-text-field
              v-model="form.additional_university_cost"
              label="Additional University Cost (FE/IR Cost)"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('additional_university_cost')"
              class="rounded-lg mb-2"
              hint="Please enter amount excluding gst"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="form.processing_cost"
              label="Processing Cost"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('processing_cost')"
              class="rounded-lg mb-2"
              hint="Please enter amount excluding gst"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="form.additional_processing_cost"
              label="Additional Processing Cost (Vendor Cost)"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('additional_processing_cost')"
              class="rounded-lg mb-2"
              hint="Please enter amount excluding gst"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="form.shipping_cost"
              label="Shipping Cost"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('shipping_cost')"
              class="rounded-lg"
            ></v-text-field>

            <v-text-field
              v-model="form.gst_percent"
              label="GST Percentage"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              @keydown="$rules.blockInvalidChar"
              :rules="[$rules.required, $rules.validateNegativeNumber]"
              @change="validateNegativeNumber('gst_percent')"
              class="rounded-lg mb-2"
              hint="GST apples on Additional University Cost, Processing Cost and Additional Processing Cost"
              persistent-hint
              disabled
            ></v-text-field>

            <v-textarea
              rows="2"
              type="text"
              v-model="form.remarks"
              flat
              outlined
              dense
              hide-details
              label="Remarks"
              class="rounded-lg"
            ></v-textarea>

            <div class="mt-5" v-if="type === $keys.ADD">
              <div>Attach Document (Optional)</div>
              <div class="file-input-container mt-2">
                <v-file-input
                  accept="image/*, application/pdf"
                  id="file-input"
                  v-show="false"
                  @change="onFileChange"
                ></v-file-input>
                <label for="file-input">
                  <div class="file-input d-flex align-center justify-center">
                    <div v-if="form.file">
                      <v-img
                        :src="form.previewFile"
                        height="120"
                        width="120"
                        v-if="form.file.type.startsWith('image/')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-icon v-else size="50">mdi-file</v-icon>
                    </div>
                    <v-icon v-else size="40">mdi-upload</v-icon>
                  </div>
                </label>
                <v-icon
                  size="20"
                  v-if="form.file"
                  color="primary"
                  @click="removeFile"
                  class="file-remover"
                  >mdi-close-circle</v-icon
                >
              </div>
            </div>
          </div>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-6 rounded-lg"
          :loading="btnLoading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      payment_mode_list: [],
      dateMenu: false,
      paymentDetail: null,
      form: {
        is_additional: true,
        payment_date: this.$moment().format("YYYY-MM-DD"),
        mode: null,
        university_cost: 0,
        additional_university_cost: 0,
        processing_cost: 0,
        additional_processing_cost: 0,
        shipping_cost: 0,
        gst_percent: 0,
        remarks: "",
        file: null,
        previewFile: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      addUpdateDialog: "additionalPayment/addUpdateDialog",
    }),

    show: {
      get() {
        return this.addUpdateDialog.show;
      },
      set(value) {
        this.toggleAddUpdateDialog({ show: value });
      },
    },

    type() {
      return this.addUpdateDialog.type;
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleAddUpdateDialog: "additionalPayment/toggleAddUpdateDialog",
    }),

    validateNegativeNumber(field) {
      if (this.form[field] < 0) {
        this.form[field] = 0;
      }
    },

    onFileChange(file) {
      if (file) {
        this.form.file = file;
        if (file.type.startsWith("image/")) {
          this.form.previewFile = URL.createObjectURL(file);
          console.log(this.form.previewFile);
          URL.revokeObjectURL(file);
        }
      } else {
        this.form.previewFile = null;
      }
    },

    removeFile() {
      this.form.file = null;
      this.form.previewFile = null;
    },

    async openModal() {
      this.loading = true;

      await this.getPaymentMode();
      await this.getDefaultPricing();

      if (this.type === this.$keys.EDIT) {
        await this.getPaymentDetail();
        const data = this.paymentDetail;
        this.form = {
          is_additional: data.is_additional,
          payment_date: data.payment_date
            ? this.$moment(data.payment_date).format("YYYY-MM-DD")
            : null,
          mode: data.mode ? data.mode.id : null,
          university_cost: data.university_cost,
          additional_university_cost: data.additional_university_cost,
          processing_cost: data.processing_cost,
          additional_processing_cost: data.additional_processing_cost,
          shipping_cost: data.shipping_cost,
          gst_percent: data.gst_percent,
          remarks: data.remarks,
        };
      }

      this.loading = false;
    },

    modalClosed() {
      this.form = {
        is_additional: true,
        payment_date: this.$moment().format("YYYY-MM-DD"),
        mode: null,
        university_cost: 0,
        additional_university_cost: 0,
        processing_cost: 0,
        additional_processing_cost: 0,
        shipping_cost: 0,
        gst_percent: 0,
        remarks: "",
        file: null,
        previewFile: null,
      };
      this.$refs.form.reset();
      this.toggleAddUpdateDialog({ show: false });
    },

    getPaymentMode() {
      const onSuccess = (res) => {
        this.payment_mode_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.paymentMode.list, {
        onSuccess,
      });
    },

    getDefaultPricing() {
      const onSuccess = (res) => {
        this.form.gst_percent = res.data.data.gst_percent;
      };

      return this.$request(this.$keys.GET, this.$urls.case.defaultPricing, {
        onSuccess,
      });
    },

    getPaymentDetail() {
      const onSuccess = (res) => {
        this.paymentDetail = res.data.data;
      };

      const params = {
        payment_id: this.addUpdateDialog.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.payment.detail, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        if (
          this.form.university_cost <= 0 &&
          this.form.additional_university_cost <= 0 &&
          this.form.processing_cost <= 0 &&
          this.form.additional_processing_cost <= 0 &&
          this.form.shipping_cost <= 0
        ) {
          this.showSnackbar({
            text: "Amount can not be 0.",
            color: "error",
          });
          this.btnLoading = false;
          return;
        }

        const params = {};
        const data = new FormData();

        data.append("is_additional", this.form.is_additional);
        data.append("payment_date", this.form.payment_date);
        data.append("mode", this.form.mode);
        data.append("university_cost", this.form.university_cost);
        data.append(
          "additional_university_cost",
          this.form.additional_university_cost
        );
        data.append("processing_cost", this.form.processing_cost);
        data.append(
          "additional_processing_cost",
          this.form.additional_processing_cost
        );
        data.append("shipping_cost", this.form.shipping_cost);
        data.append("gst_percent", this.form.gst_percent);
        data.append("remarks", this.form.remarks);

        if (this.form.file) {
          data.append("document", this.form.file);
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("on-submit");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        if (this.type === this.$keys.EDIT) {
          params["payment_id"] = this.addUpdateDialog.id;
          return this.$request(
            this.$keys.PATCH,
            this.$urls.case.payment.update,
            {
              params,
              data,
              onSuccess,
              onFinally,
            }
          );
        }

        data.append("case", this.addUpdateDialog.caseId);
        return this.$request(this.$keys.POST, this.$urls.case.payment.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
<style scoped>
.file-input-container {
  position: relative;
  border: 1px dashed #9e9e9e;
  box-sizing: border-box;
  border-radius: 20px;
  width: 130px;
  height: 130px;
}

.file-input {
  height: 100%;
  cursor: pointer;
}

.file-remover {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
</style>
